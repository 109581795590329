import * as React from "react";
import { graphql } from "gatsby";
import TemplatePage from "../../../../components/pages/templates/TemplatePage";

const Template = ({ data }) => (
  <TemplatePage
    title="Bike Rental App Template | Bike App Design | Uizard"
    category="Mobile App Templates"
    url="/templates/mobile-app-templates/bike-rental-mobile-app/"
    metaDescription="Design your mobile bike rental app with our custom-built app template, Ride It. Fast track and streamline your design flow with Uizard today."
    description="
    h2:Introducing Ride it, Uizard's bike rental app design template for mobile
    <br/>
    Looking to design your own bike rental app for your transport startup? Our new mobile bike app design template is here to give you an easy ride. Ride It, comes with everything you could possibly need to get your app design off the ground. Simply sign up to Uizard pro, open the Ride It <a:https://uizard.io/templates/mobile-app-templates/>mobile app template</a>, and adapt it to suit your vision.
    <br/>
    h3:Design your own mobile bike rental app with easy customization
    <br/>
    <a:https://uizard.io/prototyping/>UX prototyping</a> just got a whole lot easier with our stunning range of mobile app templates. As with all our templates, Ride It comes with all the must-have UI and design considerations built right in. This means you can design your own bike rental app in minutes and have a functional prototype ready to share with your team in no time at all.
    <br/>
    h3:Make multiple iteration cycles a thing of the past with smart collaboration
    <br/>
    Bike rental app design has never been easier than it is with Uizard templates. With our smart collaboration and sharing features, you can share your mockup with teammates, external stakeholders, and even customers with the click of a button. Instant feedback for your design decisions in real-time.
    "
    pages={[
      "A clean and concise log-in page for your prospective users",
      "A 'house rules' page demonstrating your terms of use",
      "Map pages showing how users will find bikes in their local area",
      "Bike lock and unlock pages with QR code system built-in",
      "User wallet and profile pages to demonstrate how users will manage payments",
    ]}
    projectCode="LWm9wj1YWqs54qAY9p1n"
    img1={data.image1.childImageSharp}
    img1alt="Bike rental app design summary page"
    img2={data.image2.childImageSharp}
    img2alt="Bike rental app design lock screen"
    img3={data.image3.childImageSharp}
    img3alt="Bike rental app design search screen"
    img4={data.image4.childImageSharp}
    img4alt="Bike rental app design settings screen"
    img5={data.image5.childImageSharp}
    img5alt="Bike rental app design rental screen"
  />
);

export default Template;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: { language: { eq: $language } }) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }

    image1: file(
      relativePath: {
        eq: "templates/bike-rental-mobile-app/bike-rental-app-cover.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image2: file(
      relativePath: {
        eq: "templates/bike-rental-mobile-app/bike-rental-lock.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image3: file(
      relativePath: {
        eq: "templates/bike-rental-mobile-app/bike-rental-search.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image4: file(
      relativePath: {
        eq: "templates/bike-rental-mobile-app/bike-rental-settings.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }

    image5: file(
      relativePath: {
        eq: "templates/bike-rental-mobile-app/bike-rental-summary.png"
      }
    ) {
      childImageSharp {
        fluid(maxWidth: 1440, quality: 100) {
          ...GatsbyImageSharpFluid_withWebp_noBase64
        }
      }
    }
  }
`;
